import React, {Component} from "react"
import {Alert, Button, Col, OverlayTrigger, Popover, ProgressBar, Row, Spinner} from "react-bootstrap";
import ReactTags from "react-tag-autocomplete";
import {Link} from "gatsby";

import {db, grav} from "../../../graphql/client.js";
import {gql} from 'apollo-boost'

import parse from "html-react-parser";
import MobilePopup from "../../MobilePopup";
import ContentLoader from "react-content-loader";

const TAGS_CLASS_NAMES = {
    root: 'symptom-tags',
    rootFocused: 'is-focused',
    selected: 'symptom-tags__selected',
    selectedTag: 'symptom-tags__selected-tag',
    selectedTagName: 'symptom-tags__selected-tag-name',
    search: 'symptom-tags__search',
    searchWrapper: 'symptom-tags__search-wrapper',
    searchInput: 'symptom-tags__search-input',
    suggestions: 'symptom-tags__suggestions',
    suggestionActive: 'is-active',
    suggestionDisabled: 'is-disabled'
};
const SCORE_WEIGHT = 15;
const DISEASES_VISIBLE = 6;

const MessageContainer = (props) => {
    return <Alert variant="danger"><h5>Error</h5><p>{props.message}</p></Alert>;
};

// @todo: cleanup code
// @todo: give proptypes
// @todo: sessionStorage for paths, save one object
// @todo: split code

class TagSearch extends Component {

    constructor(props) {

        super(props);

        this.state = {
            ...this.state,
            loading: false,
            error: false,
            errorMessage: "",
            tags: [],
            symptoms: [],
            suggestions: [],
            suggestion: false,
            lastQuery: null,
            diseases: [],
            diseaseData: {},
            diseasesVisible: DISEASES_VISIBLE,
            currentId: -1,
            showModal:false,
            menuOffset:0,
        }

        this.handleDiseaseContent = this.handleDiseaseContent.bind(this);
        this.handleDiseaseResponse = this.handleDiseaseResponse.bind(this);
        this.loadDiseases = this.loadDiseases.bind(this);

        this.stickyMenu = React.createRef();

    }

    componentDidMount() {

        const {isHomeRequest} = this.props;

        // load all diseases
        let tags = sessionStorage.getItem("tags");

        if(tags) {

            // reset if request is made from home search
            if(isHomeRequest){
                sessionStorage.removeItem("currentItemId");
                sessionStorage.removeItem("currentItemUri");
            }

            this.handleSearchAPI(JSON.parse(tags),"symptoms", isHomeRequest);
            this.setState({
                tags:JSON.parse(tags)
            });

        } else {

            this.handleSearchAPI();

        }
    }
    /**
     * handle data when tag is removed
     * @param i
     */
    onDelete (i) {

        let tags = this.state.tags && this.state.tags.slice(0);
        tags.splice(i, 1);

        let suggestions = this.state.symptoms.length > 0 && this.state.symptoms.map((suggestion)=>{
            return (!tags.find(item => item.name === suggestion.name)) ? suggestion : {};
        });

        this.setState({ tags, suggestions });

        sessionStorage.setItem("currentItemId", JSON.stringify(0));
        sessionStorage.removeItem("currentItemUri");

        if(tags.length > 0) this.handleSearchAPI(tags, "symptoms", true);
        if(tags.length === 0) {

            this.handleSearchAPI();

            sessionStorage.removeItem("tags");
        }
    }
    /**
     * handle data when tag is added
     * @param tag
     */
    onAddition (tag) {

        const tags = [].concat(this.state.tags, tag);
        const diseaseData = [];

        let suggestions = this.state.symptoms.length > 0 && this.state.symptoms.map((suggestion)=>{
            return (!tags.find(item => item.name === suggestion.name)) ? suggestion : {};
        });

        this.setState({ tags, diseaseData, suggestions, loading:true }, ()=>{

            if(tags.length > 0){
                this.handleSearchAPI(tags, "symptoms", true);
            }

            sessionStorage.removeItem("currentItemUri");

            if(tags.length === 0) this.handleSearchAPI();
        });

    }
    /**
     * load Diseases
     */
    loadDiseases(){
        this.setState((prev) => {
            return { diseasesVisible: prev.diseasesVisible + DISEASES_VISIBLE };
        });
    }
    /**
     * @todo: move ql logic
     * search the graphql api
     * by default always return diseases
     * @param query
     * @param action
     */
    handleSearchAPI (query = null, action = "", tagAdded = false) {

        this.setState({
            loading:true
        });

        switch(action) {

            // GET SYMPTOMS BASED ON SELECTED ONES
            case "symptoms":

                let qry = JSON.stringify(this.getLatestSearch(query)).replace(/(^|[^\\])"/g, '$1\\\"');
                db.cache.reset();

                db.query({
                        query: gql`
                            {
                              diseasesBySymptoms(query:"${qry}"){
                                id
                                name
                                description
                                uri
                                score
                              }
                               allSymptoms {
                                    name
                                    description
                                    id   
                                }       
                            }
                        `
                    })
                    .then((response)=>{

                        let diseases = response.data.diseasesBySymptoms;
                        diseases.sort((a,b) => b.score - a.score);

                        sessionStorage.setItem("tags", JSON.stringify(query));

                        let data = response.data.allSymptoms;
                        let symptoms = data;

                        let suggestions = data.length > 0 && data.map((suggestion)=>{
                            return (!this.state.tags.find(item => item.name === suggestion.name)) ? suggestion : {};
                        });

                        this.setState({
                            error:false,
                            diseases: diseases,
                            currentId: this.props.isMobile ? -1 : diseases[0].id,
                            suggestions:suggestions,
                            symptoms:symptoms,
                            lastQuery: query,
                            diseasesVisible:DISEASES_VISIBLE,
                            loading:false
                        });

                        if(tagAdded) sessionStorage.setItem("currentItemId", JSON.stringify(diseases[0].id - 1));

                        let activeItem = document.getElementsByTagName('disease disease-link active')[0];
                        this.handleDiseaseContent(diseases[0].uri, activeItem);

                    })
                    .catch(error => {
                        this.setState({
                            error:true,
                            errorMessage: "Can't fetch data [symptoms]"
                        });
                    });

                break;

            // SEARCH IN SYMPTOM
            case "search":

                db.query({
                        query: gql`
                        {
                            allSymptoms {
                                name
                                description
                                id   
                            }                           
                                           
                        }
                    `
                    })
                    .then((response)=>{

                        let data = response.data.allSymptoms;
                        let symptoms = data;

                        let suggestions = data.length > 0 && data.map((suggestion)=>{
                            return (!this.state.tags.find(item => item.name === suggestion.name)) ? suggestion : {};
                        });

                        let loading = false;

                        let test = suggestions.filter((obj)=>{
                            let regexp = this.matchPartial(query);
                            return regexp.test(obj.name);
                        });

                        let suggestion = test.length >= 1;

                        this.setState({ suggestions, loading, symptoms, suggestion });

                    })
                    .catch(error => {
                        this.setState({
                            error:true,
                            errorMessage: "Can't fetch data [search]"
                        });
                    });

                break;

            // DEFAULT
            default:

                this.setState({loading:true});

                db.cache.reset();
                db.query({
                    query: gql`
                        {
                           allSymptoms {
                                name
                                description
                                id   
                            }               
                            diseases {
                                id
                                name
                                description
                                uri
                                score
                            }
                        }
                    `
                })
                .then( (response) => {

                    let data = response.data.allSymptoms;
                    let symptoms = data;

                    let suggestions = data.length > 0 && data.map((suggestion)=>{
                        return (!this.state.tags.find(item => item.name === suggestion.name)) ? suggestion : {};
                    });

                    let loading = false;

                    let diseases = response.data.diseases;
                    // diseases.sort((a,b) => b.score - a.score);
                    this.setState({currentId: this.props.isMobile ? -1 : diseases[0].id, diseases, suggestions, loading, symptoms,  diseasesVisible:DISEASES_VISIBLE});

                    if(sessionStorage.getItem("currentItemId") !== null) {
                        let itemId = JSON.parse(sessionStorage.getItem("currentItemId"));
                        this.handleDiseaseContent(diseases[itemId].uri);
                    } else {
                        this.handleDiseaseContent(diseases[0].uri);
                    }



                })
                .catch(error => {
                    this.setState({
                        error:true,
                        errorMessage: "Can't fetch data [diseases]"
                    });
                });

                break;

        }

    }

    /**
     * get latest tags to prefill data
     * @param query
     * @returns {*}
     */
    getLatestSearch(query) {

        let tags = JSON.parse(sessionStorage.getItem("tags"));
        if(tags > 0) {
            return tags;
        } else {
            sessionStorage.removeItem("tags");
        }

        return query;
    }

    matchPartial(query) {
        // let q = query.replace(/\s+/g, '');
        let q = query.replace(/[°"§%()\[\]{}=\\?´`'#<>|,;.:+_-]+/g, '');
        return new RegExp(`(?:^|\\s)${q}`, 'gi')
    }

    /**
     * handleDiseaseContent
     * once a disease is clicked, get page data from grav to show summary
     * @param route
     */
    handleDiseaseContent = (route, activeItem) => {

        const {parentUrl, isMobile} = this.props;

        let uri = parentUrl + route;

        if( sessionStorage.getItem("currentItemUri") !== null ) {

            let sessionRoute = JSON.parse(sessionStorage.getItem("currentItemUri"));

            uri = parentUrl + sessionRoute;
        }

        this.setState({loading:true});


        // get page content via grav route
        grav.query({
                query: gql`
                  {
                      page(route: "${uri}") {
                        title
                        content
                        route
                        children {
                            title
                            content
                            route
                        }
                        metadata {
                          content
                        }
                      }
                    }
                `
            })
            .then((result)=>{

                this.handleDiseaseResponse(result.data);

                if( typeof activeItem !== 'undefined' && isMobile && typeof window !== 'undefined') {
                    const y = (activeItem.getBoundingClientRect().top + window.pageYOffset) - 92;
                    window.scrollTo({ top: y, behavior: "smooth" });
                }

            });
    };

    /**
     * handleDiseaseClick
     * @param e
     * @param item
     */
    handleDiseaseClick = (e, item) => {

        e.preventDefault();

        this.setState({loading:true});
        let currentId = item.id;
        let el = e.currentTarget;

        this.setState({
            currentId:currentId
        });

        // gravql api returns id's starting at 1
        // @todo make object that hold data
        sessionStorage.setItem("currentItemId", JSON.stringify(item.id - 1));
        sessionStorage.setItem("currentItemUri", JSON.stringify(item.uri));
        sessionStorage.setItem("currentItemTarget", e.currentTarget);

        this.handleDiseaseContent(item.uri, el);

    };

    /**
     * handleDiseaseResponse
     * @param data
     */
    handleDiseaseResponse = (data) => {

        const {isMobile} = this.props;

        if(data.page != null){
            let obj = {
                page:data.page,
                summary: data.page.children[0],
                disease: data.page.children[1]
            };
            this.setState({
                diseaseData: obj,
                loading:false
            });

            if(!isMobile){
                if('undefined' !== typeof this.stickyMenu && this.stickyMenu.current.clientHeight !== null) {
                    this.setState({
                        menuOffset: this.stickyMenu.current.clientHeight
                    });

                }
            }
        }

    };

    /**
     * show Mobile popup
     * */
    openMobilePopup() {
        if(typeof window !== 'undefined') {
            this.setState({
                showModal : true
            }, ()=>{
                document.getElementsByClassName("symptom-tags__search-input")[0].focus();
                setTimeout(()=>{
                    window.scroll(0, 0);
                },200);
            });
        }
    }

    render() {

        const {parentUrl, isMobile} = this.props;
        const {error, errorMessage, currentId, diseaseData, diseasesVisible, diseases, showModal, suggestions, suggestion, menuOffset} = this.state;

        return <React.Fragment>
                <Row>
                    <Col xs={12} lg={12}>
                        <span className="label-bold">Verfijn zoekresultaat</span>
                    </Col>
                </Row>
                {isMobile &&
                <Row>
                    <Col xs={12} lg={12}>
                        <MobilePopup
                            title={'Zoek op symptoom'}
                            isMobile={isMobile}
                            open={showModal}
                            close={()=>{
                                this.setState({showModal : false})
                            }}
                        >
                            <div className={`${(!suggestion) ? 'symptom-tags__no-results' : ''}`}>
                            <ReactTags tags={ this.state.tags }
                                       suggestionsFilter={(item, query)=>{
                                           let name = item.name;
                                           let regexp = this.matchPartial(query);
                                           return (item.disabled) ? true : regexp.test(name);
                                       }}
                                       classNames={TAGS_CLASS_NAMES}
                                       suggestions={suggestion ? suggestions :  [{id:1, name:'Geen symptomen gevonden', disabled:true}]}
                                       allowBackspace={false}
                                       focused={true}
                                       autoresize={false}
                                       minQueryLength={1}
                                       onDelete={this.onDelete.bind(this)}
                                       onAddition={this.onAddition.bind(this)}
                                       onInput={(query)=>{
                                           let test = suggestions.filter((obj)=>{
                                               let regexp = this.matchPartial(query);
                                               return regexp.test(obj.name);
                                           });
                                           this.setState({
                                               suggestion: test.length >= 1
                                           });
                                       }}
                                       placeholderText={"Zoek op symptoom"}
                                       removeButtonText={"Symptoom verwijderen"}
                            />
                            </div>
                            <a href="#" className="btn secondary-btn btn-block" onClick={()=>{this.setState({showModal : false})}}>
                                { (this.state.loading) ?
                                <Spinner size="sm" animation="border" /> :
                                <span className="label-bold">Toon {diseases.length} {(diseases.length > 1) ? "resultaten" : "resultaat"}</span>
                                }
                            </a>
                        </MobilePopup>
                        <div className="symptom-tags__search">
                            <div className="symptom-tags__search-wrapper">
                                <input
                                    placeholder="Zoek op symptoom"
                                    className="symptom-tags__search-input-btn"
                                    onClick={()=>this.openMobilePopup()}/>
                            </div>
                        </div>
                    </Col>
                </Row> }
                {!isMobile &&<Row>
                    <Col xs={12} lg={12}>
                            { error &&  <MessageContainer message={errorMessage}/> }
                            <div className={`${(!suggestion) ? 'symptom-tags__no-results' : ''}`}>
                            <ReactTags tags={ this.state.tags }
                                       suggestionsFilter={(item, query)=>{
                                           let name = item.name;
                                           let regexp = this.matchPartial(query);
                                           return (item.disabled) ? true : regexp.test(name);
                                       }}
                                       classNames={TAGS_CLASS_NAMES}
                                       allowBackspace={false}
                                       autoresize={false}
                                       minQueryLength={2}
                                       suggestions={suggestion ? suggestions :  [{id:1, name:'Geen symptomen gevonden', disabled:true}]}
                                       onDelete={this.onDelete.bind(this)}
                                       onAddition={this.onAddition.bind(this)}
                                       onInput={(query)=>{
                                           let test = suggestions.filter((obj)=>{
                                               let regexp = this.matchPartial(query);
                                               return regexp.test(obj.name);
                                           });
                                           this.setState({
                                               suggestion: test.length >= 1
                                           });
                                       }}
                                       placeholderText={"Zoek op symptoom"}
                                       removeButtonText={"Symptoom verwijderen"}
                            />
                            </div>
                    </Col>
                </Row> }
                <Row className={`searchSymptoms-results`} style={{marginBottom:menuOffset}}>
                    <Col xs={12} lg={4}>
                        <div className="searchSymptoms-results__resultLabelWrap">
                            { (this.state.loading) ? <Spinner size="sm" animation="border" /> :  <span className="label-bold">{diseases.length} {(diseases.length > 1) ? "resultaten" : "resultaat"}</span> }
                            <OverlayTrigger trigger={['click', 'hover', 'focus']} placement="top" overlay={
                                <Popover id="popover-basic">
                                    <Popover.Title as="h3">Resultaten</Popover.Title>
                                    <Popover.Content>
                                        De resultaten worden bepaald a.d.h.v. de invoer in de zoekbalk.
                                    </Popover.Content>
                                </Popover>
                            }>
                                <a href="#"  data-toggle="popover" className="searchSymptoms-results__infoBtn"/>
                            </OverlayTrigger>
                        </div>

                        { diseases.length == 0 && <div className="searchSymptoms-results__result">
                            <ContentLoader
                                speed={1}
                                primaryColor="#f3f3f3"
                                secondaryColor="#ecebeb"
                                viewBox={"0 0 400 600"}
                            >
                                {[0,1,2,3,4,5].map((o, i)=>{
                                        return <rect key={i} x="0" y={`${(i*75) + 10}`} rx="0" ry="0" width="100%" height={65} />

                                })}
                            </ContentLoader>
                        </div>
                        }

                        {diseases.length > 0 && diseases.slice(0, diseasesVisible).map((item, i)=>{

                            let score = ((item.score * SCORE_WEIGHT) <=100) ? (item.score * SCORE_WEIGHT) : 100;
                            let storedId = (sessionStorage.getItem("currentItemId") !== null) ? JSON.parse(sessionStorage.getItem("currentItemId")) : (parseInt(currentId)-1);

                            return <div key={i} className="searchSymptoms-results__result">
                                <Link to={parentUrl+item.uri} onClick={(e)=>this.handleDiseaseClick(e, item)} className={ `disease disease-link ${(storedId === (parseInt(item.id)-1)) ? 'active' : ''}` }>
                                    <h5>{item.name}</h5>
                                    {/*<ProgressBar now={(this.state.tags.length > 0) ? score : 0} />*/}
                                </Link>
                                { /* Mobile Summary View */ }
                                {isMobile && diseaseData.page && (storedId === (parseInt(item.id)-1)) && <div className="searchSymptoms-results__result-summary">
                                        <h5>{ diseaseData.page.title }</h5>
                                        { diseaseData.summary.content && parse(diseaseData.summary.content) }
                                        { diseaseData.page.route && <Link to={diseaseData.page.route} onClick={()=>{
                                            sessionStorage.setItem("currentItemId", JSON.stringify(item.id))
                                        }} className="btn btn-secondary btn-lg">Alles over {diseaseData.page.title}</Link> }
                                    </div>
                                }
                            </div>
                        })}
                        {diseases.length > 6 && diseasesVisible < diseases.length && <div className="searchSymptoms-results__loadMoreBtnWrap"><Button variant="light" onClick={this.loadDiseases}>Meer laden <ProgressBar striped now={(100/diseases.length)*(diseasesVisible)}/></Button></div>}
                    </Col>

                    { this.state.loading && !isMobile && <Col xs={12} lg={8}>
                        <ContentLoader
                            viewBox={"0 0 800 300"}
                            speed={1}
                            primaryColor="#f3f3f3"
                            secondaryColor="#ecebeb"
                        >
                            <rect x="0" y="0" rx="0" ry="0" width="258" height="25" />
                            <rect x="0" y="32" rx="0" ry="0" width="300" height="45" />
                            <rect x="0" y="84" rx="0" ry="0" width="100%" height="20" />
                            <rect x="0" y="114" rx="0" ry="0" width="100%" height="20" />
                            <rect x="0" y="144" rx="0" ry="0" width="100%" height="20" />
                            <rect x="0" y="174" rx="0" ry="0" width="100%" height="20" />
                            <rect x="0" y="204" rx="0" ry="0" width="100%" height="20" />
                            <rect x="0" y="234" rx="0" ry="0" width="100%" height="20" />
                            <rect x="0" y="264" rx="0" ry="0" width="100%" height="20" />
                        </ContentLoader>
                    </Col>
                    }

                    { /* Desktop Summary View */ }
                    {!isMobile && <Col xs={12} lg={8}>
                        {diseaseData.page && diseaseData.summary && <React.Fragment>
                        <div className="sticky-menu">
                        { !this.state.loading && <div ref={this.stickyMenu}>
                            <Link to={diseaseData.page.route} className={'searchSymptoms-results__disease-title'}><h5>{ diseaseData.page.title }</h5></Link>
                            { diseaseData.summary.content && parse(diseaseData.summary.content) }
                            { diseaseData.page.route && <Link to={diseaseData.page.route} className="btn btn-secondary btn-lg">Alles over {diseaseData.page.title}</Link> }
                        </div>}
                        </div>
                    </React.Fragment>}
                    </Col>}
                </Row>
        </React.Fragment>;
    }
}
export default TagSearch;
