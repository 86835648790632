import React, {Component} from "react"
import BaseLayout from "../components/Layout/BaseLayout";
import {graphql} from "gatsby";

import TagSearch from "../components/Layout/TagSearch";
import ResponsiveContainer from "../components/ResponsiveContainer";
import {DISEASES_URL} from "../constants";

class SymptomSearch extends Component {


    constructor(props){

        super(props);
        this.state = {
            show:false,
            tags: [],
            suggestions: [],
            diseases: []
        }
    }

    componentDidMount(){
        this.setState({
            show:true
        });
    }

    render() {

        const {data, location} = this.props;

        return (
            <BaseLayout
                showSubNav={true}
                show={this.state.show}
                data={data.gravql}
                crumbLabel={data.gravql.page.title}
                location={location}>
                    <h1>Zoek op symptoom</h1>
                    <div className="content symptom-search">
                        {
                            data.gravql.page.content
                        }
                    </div>
                    <ResponsiveContainer>
                        <TagSearch
                            parentUrl={DISEASES_URL}
                            isHomeRequest={(location.state) ? location.state.isHomeRequest : false}
                        />
                    </ResponsiveContainer>
            </BaseLayout>
        )
    }
}

export default SymptomSearch;

export const query = graphql`
 query ($slug:String!) {
    gravql {
        pages {
            title
            route
            visible
            header {
                title
                body_classes
            }
            children {
                title
                route
                header {
                    title
                    body_classes
                }
            }
        }
        page(route: $slug) {
            title      
            content
            visible
            breadcrumbs
            header {
                body_classes
            }
            route
            children {
                title
                route                
            }          
            metadata {
                name
                content
            }   
        }
    }
  }
`;
